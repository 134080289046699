@use '@techniek-team/lyceo-style' as typo;

/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
@import 'theme/material/mat-theme';

/* Core CSS required for Ionic components to work properly */
@import '@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';

/* Custom style */
@import 'theme/ionic/ion-button';
@import 'theme/ionic/ion-card';
@import 'theme/ionic/ion-progress-bar';
@import 'theme/ionic/ion-skeleton-text';
@import 'theme/variables-subject-colors';

@import '@techniek-team/lyceo-style/src/scss/ionic-lyceo-theme.dark';
@import '@techniek-team/lyceo-style/src/scss/ionic-lyceo-theme';

ion-input,
.sc-ion-input-md-h,
ion-textarea,
.native-textarea.sc-ion-textarea-md,
form {
  @include typo.typography(typo.tt-body-2());
}

:root {
  --lyceo-grey-background-rgb: 243, 243, 243;
  --ion-background-color: var(--lyceo-grey-background);
  --ion-background-color-rgb: var(--lyceo-grey-background-rgb);
  --ion-toolbar-background: var(--lyceo-grey-background);
  --border-color: var(--lyceo-dark-blue-95);
  --ion-color-step-500: var(--lyceo-dark-blue-95);
  --ion-item-border-color: var(--lyceo-dark-blue-95);
}

.cdk-overlay-container {
  display: contents;
  & div:has([data-test='tt-mouseover-dialog']) {
    z-index: 30001;
  }
}

ion-modal,
.tt-routing-modal {
  --background: white;

  ion-content {
    --background: white;
  }
}

a {
  color: var(--lyceo-green-40);
}

.link {
  color: var(--lyceo-green-40);
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.popover {
  padding: 8px;
  margin-top: 4px;
  border-radius: 4px;
  font-size: 0.75rem;
  line-height: 1rem;
  background: rgba(var(--lyceo-dark-blue-rgb), 0.9);
  color: var(--lyceo-dark-blue-contrast);
  max-width: 500px;

  p {
    font-size: inherit;
    line-height: inherit;

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .link {
    color: var(--lyceo-green-70);
  }
}

/**
 * Styles as defined by the Lyceo UI guidebook
 */

.flex {
  display: flex;
}

.ml-auto {
  margin-left: auto;
}

.inline-block {
  display: inline-block;
}

.ripple-parent {
  position: relative;
  overflow: hidden;
}

ion-router-outlet.modal-outlet,
router-outlet.modal-outlet {
  width: 0;
  height: 0;
}
