@import 'colors_variables';

:root {
  --lyceo-grey-background: #f3f3f3;
  --lyceo-white-background: #ffffff;
  --lyceo-white-background-rgb: 255, 255, 255;
  --lyceo-dark-text-color: #0f1522;
  --lyceo-light-contrast-color-rgb: 255, 255, 255;
  --lyceo-dark-contrast-color-rgb: 15, 21, 34;
  --lyceo-light-text-color: #ffffff;
  --lyceo-light-text-color-rgb: 255, 255, 255;
  --lyceo-element-text-color-mint: var(--lyceo-green-60);

  /** Lyceo Dark Blue **/
  --lyceo-dark-blue-0: #000000;
  --lyceo-dark-blue-0-contrast: var(--lyceo-light-text-color);
  --lyceo-dark-blue-10: #06080e;
  --lyceo-dark-blue-10-contrast: var(--lyceo-light-text-color);
  --lyceo-dark-blue-20: #0a0e17;
  --lyceo-dark-blue-20-contrast: var(--lyceo-light-text-color);
  --lyceo-dark-blue-30: #101522;
  --lyceo-dark-blue-30-contrast: var(--lyceo-light-text-color);
  --lyceo-dark-blue-40: #151c2d;
  --lyceo-dark-blue-40-contrast: var(--lyceo-light-text-color);
  --lyceo-dark-blue-50: #1a2339;
  --lyceo-dark-blue-50-contrast: var(--lyceo-light-text-color);
  --lyceo-dark-blue-60: #1f2a44;
  --lyceo-dark-blue-60-contrast: var(--lyceo-light-text-color);
  --lyceo-dark-blue-70: #444e63;
  --lyceo-dark-blue-70-contrast: var(--lyceo-light-text-color);
  --lyceo-dark-blue-80: #6a7182;
  --lyceo-dark-blue-80-contrast: var(--lyceo-light-text-color);
  --lyceo-dark-blue-90: #8f94a1;
  --lyceo-dark-blue-90-contrast: var(--lyceo-dark-text-color);
  --lyceo-dark-blue-95: #b4b8c1;
  --lyceo-dark-blue-95-contrast: var(--lyceo-dark-text-color);
  --lyceo-dark-blue-99: #d2d4da;
  --lyceo-dark-blue-99-contrast: var(--lyceo-dark-text-color);
  --lyceo-dark-blue-100: #ffffff;
  --lyceo-dark-blue-100-contrast: var(--lyceo-dark-text-color);

  --lyceo-dark-blue: var(--lyceo-dark-blue-50);
  --lyceo-dark-blue-rgb: 21, 28, 45;
  --lyceo-dark-blue-contrast: var(--lyceo-dark-blue-50-contrast);
  --lyceo-dark-blue-contrast-rgb: var(--lyceo-light-contrast-color-rgb);
  --lyceo-dark-blue-shade: var(--lyceo-dark-blue-30);
  --lyceo-dark-blue-shade-contrast: var(--lyceo-dark-blue-30-contrast);
  --lyceo-dark-blue-tint: var(--lyceo-dark-blue-70);
  --lyceo-dark-blue-tint-contrast: var(--lyceo-dark-blue-70-contrast);
  /*********************/

  /** Lyceo Green **/
  --lyceo-green: var(--lyceo-green-40);
  --lyceo-green-rgb: 27, 131, 121;
  --lyceo-green-contrast: var(--lyceo-green-40-contrast);
  --lyceo-green-contrast-rgb: var(--lyceo-light-contrast-color-rgb);
  --lyceo-green-shade: var(--lyceo-green-20);
  --lyceo-green-shade-contrast: var(--lyceo-green-20-contrast);
  --lyceo-green-tint: var(--lyceo-green-60);
  --lyceo-green-tint-contrast: var(--lyceo-green-60-contrast);

  --lyceo-green-0: #000000;
  --lyceo-green-0-contrast: var(--lyceo-light-text-color);
  --lyceo-green-10: #092b27;
  --lyceo-green-10-contrast: var(--lyceo-light-text-color);
  --lyceo-green-20: #0f4741;
  --lyceo-green-20-contrast: var(--lyceo-light-text-color);
  --lyceo-green-30: #166b62;
  --lyceo-green-30-contrast: var(--lyceo-light-text-color);
  --lyceo-green-40: #1b8379;
  --lyceo-green-40-contrast: var(--lyceo-light-text-color);
  --lyceo-green-50: #25b1a3;
  --lyceo-green-50-contrast: var(--lyceo-dark-text-color);
  --lyceo-green-60: #2cd5c4;
  --lyceo-green-60-contrast: var(--lyceo-dark-text-color);
  --lyceo-green-70: #4fdcce;
  --lyceo-green-70-contrast: var(--lyceo-dark-text-color);
  --lyceo-green-80: #72e3d8;
  --lyceo-green-80-contrast: var(--lyceo-dark-text-color);
  --lyceo-green-90: #95eae1;
  --lyceo-green-90-contrast: var(--lyceo-dark-text-color);
  --lyceo-green-95: #b9f1eb;
  --lyceo-green-95-contrast: var(--lyceo-dark-text-color);
  --lyceo-green-99: #d5f7f3;
  --lyceo-green-99-contrast: var(--lyceo-dark-text-color);
  --lyceo-green-100: #ffffff;
  --lyceo-green-100-contrast: var(--lyceo-dark-text-color);
  /*********************/

  /** Lyceo Red **/
  --lyceo-red: var(--lyceo-red-40);
  --lyceo-red-rgb: 203, 45, 63;
  --lyceo-red-contrast: var(--lyceo-red-40-contrast);
  --lyceo-red-contrast-rgb: var(--lyceo-light-contrast-color-rgb);
  --lyceo-red-shade: var(--lyceo-red-20);
  --lyceo-red-shade-contrast: var(--lyceo-red-20-contrast);
  --lyceo-red-tint: var(--lyceo-red-60);
  --lyceo-red-tint-contrast: var(--lyceo-red-60-contrast);

  --lyceo-red-0: #000000;
  --lyceo-red-0-contrast: var(--lyceo-light-text-color);
  --lyceo-red-10: #310b0f;
  --lyceo-red-10-contrast: var(--lyceo-light-text-color);
  --lyceo-red-20: #511219;
  --lyceo-red-20-contrast: var(--lyceo-light-text-color);
  --lyceo-red-30: #7a1b26;
  --lyceo-red-30-contrast: var(--lyceo-light-text-color);
  --lyceo-red-40: #cb2d3f;
  --lyceo-red-40-contrast: var(--lyceo-light-text-color);
  --lyceo-red-50: #e4253b;
  --lyceo-red-50-contrast: var(--lyceo-light-text-color);
  --lyceo-red-60: #f4364c;
  --lyceo-red-60-contrast: var(--lyceo-dark-text-color);
  --lyceo-red-70: #f65f71;
  --lyceo-red-70-contrast: var(--lyceo-dark-text-color);
  --lyceo-red-80: #f87988;
  --lyceo-red-80-contrast: var(--lyceo-dark-text-color);
  --lyceo-red-90: #f99aa5;
  --lyceo-red-90-contrast: var(--lyceo-dark-text-color);
  --lyceo-red-95: #fbbcc3;
  --lyceo-red-95-contrast: var(--lyceo-dark-text-color);
  --lyceo-red-99: #fdd7db;
  --lyceo-red-99-contrast: var(--lyceo-dark-text-color);
  --lyceo-red-100: #ffffff;
  --lyceo-red-100-contrast: var(--lceo-dark-contrast-color);
  /*********************/

  /** Lyceo Geel **/
  --lyceo-yellow: var(--lyceo-yellow-40);
  --lyceo-yellow-rgb: 158, 109, 0;
  --lyceo-yellow-contrast: var(--lyceo-yellow-40-contrast);
  --lyceo-yellow-contrast-rgb: var(--lyceo-light-contrast-color-rgb);
  --lyceo-yellow-shade: var(--lyceo-yellow-20);
  --lyceo-yellow-shade-contrast: var(--lyceo-yellow-20-contrast);
  --lyceo-yellow-tint: var(--lyceo-yellow-20);
  --lyceo-yellow-tint-contrast: var(--lyceo-yellow-20-contrast);

  --lyceo-yellow-0: #000000;
  --lyceo-yellow-0-contrast: var(--lyceo-light-text-color);
  --lyceo-yellow-10: #332506;
  --lyceo-yellow-10-contrast: var(--lyceo-light-text-color);
  --lyceo-yellow-20: #553d09;
  --lyceo-yellow-20-contrast: var(--lyceo-light-text-color);
  --lyceo-yellow-30: #805c0e;
  --lyceo-yellow-30-contrast: var(--lyceo-light-text-color);
  --lyceo-yellow-40: #9e6d00;
  --lyceo-yellow-40-contrast: var(--lyceo-light-text-color);
  --lyceo-yellow-50: #d49917;
  --lyceo-yellow-50-contrast: var(--lyceo-dark-text-color);
  --lyceo-yellow-60: #ffb81c;
  --lyceo-yellow-60-contrast: var(--lyceo-dark-text-color);
  --lyceo-yellow-70: #ffc442;
  --lyceo-yellow-70-contrast: var(--lyceo-dark-text-color);
  --lyceo-yellow-80: #ffd068;
  --lyceo-yellow-80-contrast: var(--lyceo-dark-text-color);
  --lyceo-yellow-90: #ffdb8d;
  --lyceo-yellow-90-contrast: var(--lyceo-dark-text-color);
  --lyceo-yellow-95: #ffe7b3;
  --lyceo-yellow-95-contrast: var(--lyceo-dark-text-color);
  --lyceo-yellow-99: #fff1d2;
  --lyceo-yellow-99-contrast: var(--lyceo-dark-text-color);
  --lyceo-yellow-100: #ffffff;
  --lyceo-yellow-100-contrast: var(--lceo-dark-contrast-color);
  /*********************/

  /** Lyceo medium **/
  --lyceo-medium: var(--lyceo-dark-blue-90);
  --lyceo-medium-rgb: 152, 154, 162;
  --lyceo-medium-contrast: var(--lyceo-dark-blue-95-contrast);
  --lyceo-medium-contrast-rgb: 15, 21, 34;
  --lyceo-medium-shade: var(--lyceo-dark-blue-80);
  --lyceo-medium-tint: var(--lyceo-dark-blue-99);

  /** Lyceo Light **/
  --lyceo-light: var(--lyceo-grey-background);
  --lyceo-light-rgb: 243, 243, 243;
  --lyceo-light-contrast: #0f1522;
  --lyceo-light-contrast-rgb: 15, 21, 34;
  --lyceo-light-shade: #d6d6d6;
  --lyceo-light-tint: #f4f4f4;

  /** Lyceo Danger **/
  --lyceo-danger: var(--lyceo-red-60);
  --lyceo-danger-rgb: 244, 54, 76;
  --lyceo-danger-contrast: var(--lyceo-red-60-contrast);
  --lyceo-danger-contrast-rgb: var(--lyceo-dark-contrast-color-rgb);
  --lyceo-danger-shade: var(--lyceo-red-40);
  --lyceo-danger-tint: var(--lyceo-red-80);

  /** Lyceo Warning **/
  --lyceo-warning: var(--lyceo-yellow-60);
  --lyceo-warning-rgb: 255, 184, 28;
  --lyceo-warning-contrast: var(--lyceo-yellow-60-contrast);
  --lyceo-warning-contrast-rgb: var(--lyceo-dark-contrast-color-rgb);
  --lyceo-warning-shade: var(--lyceo-yellow-40);
  --lyceo-warning-tint: var(--lyceo-yellow-80);

  /** Lyceo Success **/
  --lyceo-success: var(--lyceo-green-60);
  --lyceo-success-rgb: 44, 213, 196;
  --lyceo-success-contrast: var(--lyceo-green-60-contrast);
  --lyceo-success-contrast-rgb: var(--lyceo-dark-contrast-color-rgb);
  --lyceo-success-shade: var(--lyceo-green-40);
  --lyceo-success-tint: var(--lyceo-green-80);

  /** @deprecated use --lyceo-green instead **/
  --lyceo-mint: var(--lyceo-green);
  /** @deprecated use --lyceo-green instead **/
  --lyceo-mint-rgb: var(--lyceo-green-rgb);
  /** @deprecated use --lyceo-green instead **/
  --lyceo-mint-contrast: var(--lyceo-green-contrast);
  /** @deprecated use --lyceo-green instead **/
  --lyceo-mint-contrast-rgb: var(--lyceo-green-contrast-rgb);
  /** @deprecated use --lyceo-green instead **/
  --lyceo-mint-shade: var(--lyceo-green-shade);
  --lyceo-mint-shade-contrast: var(--lyceo-green-shade-contrast);
  /** @deprecated use --lyceo-green instead **/
  --lyceo-mint-tint: var(--lyceo-green-tint);
  --lyceo-mint-tint-contrast: var(--lyceo-green-tint-contrast);
}

.ion-color-lyceo-dark-blue {
  /* stylelint-disable-next-line declaration-no-important */
  --ion-color-base: var(--lyceo-dark-blue) !important;
  /* stylelint-disable-next-line declaration-no-important */
  --ion-color-base-rgb: var(--lyceo-dark-blue-rgb) !important;
  /* stylelint-disable-next-line declaration-no-important */
  --ion-color-contrast: var(--lyceo-dark-blue-contrast) !important;
  /* stylelint-disable-next-line declaration-no-important */
  --ion-color-contrast-rgb: var(--lyceo-dark-blue-contrast-rgb) !important;
  /* stylelint-disable-next-line declaration-no-important */
  --ion-color-shade: var(--lyceo-dark-blue-shade) !important;
  /* stylelint-disable-next-line declaration-no-important */
  --ion-color-shade-contrast: var(--lyceo-dark-blue-shade-contrast) !important;
  /* stylelint-disable-next-line declaration-no-important */
  --ion-color-tint: var(--lyceo-dark-blue-tint) !important;
  /* stylelint-disable-next-line declaration-no-important */
  --ion-color-tint-contrast: var(--lyceo-dark-blue-tint-contrast) !important;
}

.ion-color-lyceo-red {
  /* stylelint-disable-next-line declaration-no-important */
  --ion-color-base: var(--lyceo-red) !important;
  /* stylelint-disable-next-line declaration-no-important */
  --ion-color-base-rgb: var(--lyceo-red-rgb) !important;
  /* stylelint-disable-next-line declaration-no-important */
  --ion-color-contrast: var(--lyceo-red-contrast) !important;
  /* stylelint-disable-next-line declaration-no-important */
  --ion-color-contrast-rgb: var(--lyceo-red-contrast-rgb) !important;
  /* stylelint-disable-next-line declaration-no-important */
  --ion-color-shade: var(--lyceo-red-shade) !important;
  /* stylelint-disable-next-line declaration-no-important */
  --ion-color-shade-contrast: var(--lyceo-red-shade-contrast) !important;
  /* stylelint-disable-next-line declaration-no-important */
  --ion-color-tint: var(--lyceo-red-tint) !important;
  /* stylelint-disable-next-line declaration-no-important */
  --ion-color-tint-contrast: var(--lyceo-red-tint-contrast) !important;
}

.ion-color-lyceo-yellow {
  /* stylelint-disable-next-line declaration-no-important */
  --ion-color-base: var(--lyceo-yellow) !important;
  /* stylelint-disable-next-line declaration-no-important */
  --ion-color-base-rgb: var(--lyceo-yellow-rgb) !important;
  /* stylelint-disable-next-line declaration-no-important */
  --ion-color-contrast: var(--lyceo-yellow-contrast) !important;
  /* stylelint-disable-next-line declaration-no-important */
  --ion-color-contrast-rgb: var(--lyceo-yellow-contrast-rgb) !important;
  /* stylelint-disable-next-line declaration-no-important */
  --ion-color-shade: var(--lyceo-yellow-shade) !important;
  /* stylelint-disable-next-line declaration-no-important */
  --ion-color-shade-contrast: var(--lyceo-yellow-shade-contrast) !important;
  /* stylelint-disable-next-line declaration-no-important */
  --ion-color-tint: var(--lyceo-yellow-tint) !important;
  /* stylelint-disable-next-line declaration-no-important */
  --ion-color-tint-contrast: var(--lyceo-yellow-tint-contrast) !important;
}

.ion-color-lyceo-green {
  /* stylelint-disable-next-line declaration-no-important */
  --ion-color-base: var(--lyceo-green) !important;
  /* stylelint-disable-next-line declaration-no-important */
  --ion-color-base-rgb: var(--lyceo-green-rgb) !important;
  /* stylelint-disable-next-line declaration-no-important */
  --ion-color-contrast: var(--lyceo-green-contrast) !important;
  /* stylelint-disable-next-line declaration-no-important */
  --ion-color-contrast-rgb: var(--lyceo-green-contrast-rgb) !important;
  /* stylelint-disable-next-line declaration-no-important */
  --ion-color-shade: var(--lyceo-green-shade) !important;
  /* stylelint-disable-next-line declaration-no-important */
  --ion-color-shade-contrast: var(--lyceo-green-shade-contrast) !important;
  /* stylelint-disable-next-line declaration-no-important */
  --ion-color-tint: var(--lyceo-green-tint) !important;
  /* stylelint-disable-next-line declaration-no-important */
  --ion-color-tint-contrast: var(--lyceo-green-tint-contrast) !important;
}
