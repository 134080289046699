body.dark {
  color: var(--ion-light-color);
  --tt-icon-size: 24px;
  --ion-font-family: 'Rubik', sans-serif;

  --ion-background-color: var(--lyceo-dark-blue-70);
  --ion-background-color-rgb: 31, 42, 68;
  --ion-text-color: var(--lyceo-light-text-color);
  --ion-text-color-rgb: var(--lyceo-light-text-color-rgb);
  --ion-backdrop-color: var(--lyceo-dark-blue);
  --ion-backdrop-color-opacity: 91;
  --ion-overlay-background-color: var(--lyceo-dark-blue-80);
  --ion-border-color: var(--lyceo-dark-blue-99);
  --ion-box-shadow-color: var(--lyceo-dark-blue-99);
  --ion-tab-bar-background: var(--lyceo-dark-blue-60);
  --ion-tab-bar-background-focused: var(--lyceo-dark-blue-60);
  --ion-tab-bar-border-color: var(--lyceo-green-60);
  --ion-tab-bar-color: var(--lyceo-green-60);
  --ion-tab-bar-color-selected: var(--lyceo-green-60);
  --ion-toolbar-background: var(--lyceo-dark-blue-80);
  --ion-toolbar-border-color: var(--lyceo-dark-blue-99);
  --ion-toolbar-color: var(--lyceo-light-text-color);
  --ion-toolbar-color-activated: rgba(var(--lyceo-dark-blue-rgb), 0.78);
  --ion-toolbar-color-checked: rgba(var(--lyceo-dark-blue-rgb), 0.78);
  --ion-item-color: var(--lyceo-light-text-color);
  --ion-item-background: var(--lyceo-dark-blue-60);
  --ion-item-border-color: var(--lyceo-dark-blue-95);
  --ion-placeholder-color: var(--lyceo-dark-blue-95);

  --ion-background-color-step-50: var(--lyceo-dark-blue-99);
  --ion-background-color-step-100: var(--lyceo-dark-blue-99);
  --ion-background-color-step-150: var(--lyceo-dark-blue-95);
  --ion-background-color-step-200: var(--lyceo-dark-blue-95);
  --ion-background-color-step-250: var(--lyceo-dark-blue-90);
  --ion-background-color-step-300: var(--lyceo-dark-blue-90);
  --ion-background-color-step-350: var(--lyceo-dark-blue-80);
  --ion-background-color-step-400: var(--lyceo-dark-blue-80);
  --ion-background-color-step-450: var(--lyceo-dark-blue-70);
  --ion-background-color-step-500: var(--lyceo-dark-blue-70);
  --ion-background-color-step-550: var(--lyceo-dark-blue-60);
  --ion-background-color-step-600: var(--lyceo-dark-blue-60);
  --ion-background-color-step-650: var(--lyceo-dark-blue-50);
  --ion-background-color-step-700: var(--lyceo-dark-blue-50);
  --ion-background-color-step-750: var(--lyceo-dark-blue-40);
  --ion-background-color-step-800: var(--lyceo-dark-blue-40);
  --ion-background-color-step-850: var(--lyceo-dark-blue-30);
  --ion-background-color-step-900: var(--lyceo-dark-blue-20);
  --ion-background-color-step-950: var(--lyceo-dark-blue-10);
}
