/** Lyceo variables **/
@import 'ionic-variables.dark';

/** Ionic CSS Variables **/
body.dark {
  /** primary **/
  --ion-color-primary: var(--lyceo-dark-blue-contrast);
  --ion-color-primary-rgb: var(--lyceo-dark-blue-contrast-rgb);
  --ion-color-primary-contrast: var(--lyceo-dark-blue);
  --ion-color-primary-contrast-rgb: var(--lyceo-dark-blue-rgb);
  --ion-color-primary-shade: var(--lyceo-dark-blue-80);
  --ion-color-primary-tint: var(--lyceo-dark-blue-contrast);

  /** secondary **/
  --ion-color-secondary: var(--lyceo-green-60);
  --ion-color-secondary-rgb: 44, 213, 196;
  --ion-color-secondary-contrast: var(--lyceo-green-60-contrast);
  --ion-color-secondary-contrast-rgb: var(--lyceo-dark-contrast-color-rgb);
  --ion-color-secondary-shade: var(--lyceo-green-40);
  --ion-color-secondary-tint: var(--lyceo-green-80);

  /** tertiary **/
  --ion-color-tertiary: var(--lyceo-dark-blue-70);
  --ion-color-tertiary-rgb: var(--lyceo-red-rgb);
  --ion-color-tertiary-contrast: var(--lyceo-dark-blue-70-contrast);
  --ion-color-tertiary-contrast-rgb: var(--lyceo-light-text-color-rgb);
  --ion-color-tertiary-shade: var(--lyceo-dark-blue-50);
  --ion-color-tertiary-tint: var(--lyceo-dark-blue-90);

  /** success **/
  --ion-color-success: var(--lyceo-success);
  --ion-color-success-rgb: var(--lyceo-success-rgb);
  --ion-color-success-contrast: var(--lyceo-success-contrast);
  --ion-color-success-contrast-rgb: var(--lyceo-success-contrast-rgb);
  --ion-color-success-shade: var(--lyceo-success-shade);
  --ion-color-success-tint: var(--lyceo-success-tint);

  /** warning **/
  --ion-color-warning: var(--lyceo-warning);
  --ion-color-warning-rgb: var(--lyceo-warning-rgb);
  --ion-color-warning-contrast: var(--lyceo-warning-contrast);
  --ion-color-warning-contrast-rgb: var(--lyceo-warning-contrast-rgb);
  --ion-color-warning-shade: var(--lyceo-warning-shade);
  --ion-color-warning-tint: var(--lyceo-warning-tint);

  /** danger **/
  --ion-color-danger: var(--lyceo-danger);
  --ion-color-danger-rgb: var(--lyceo-danger-rgb);
  --ion-color-danger-contrast: var(--lyceo-danger-contrast);
  --ion-color-danger-contrast-rgb: var(--lyceo-danger-contrast-rgb);
  --ion-color-danger-shade: var(--lyceo-danger-shade);
  --ion-color-danger-tint: var(--lyceo-danger-tint);

  /** dark **/
  --ion-color-dark: var(--lyceo-dark-blue);
  --ion-color-dark-rgb: var(--lyceo-dark-blue-rgb);
  --ion-color-dark-contrast: var(--lyceo-dark-blue-contrast);
  --ion-color-dark-contrast-rgb: var(--lyceo-dark-blue-contrast-rgb);
  --ion-color-dark-shade: var(--lyceo-dark-blue-shade);
  --ion-color-dark-tint: var(--lyceo-dark-blue-tint);

  /** medium **/
  --ion-color-medium: var(--lyceo-medium);
  --ion-color-medium-rgb: var(--lyceo-medium-rgb);
  --ion-color-medium-contrast: var(--lyceo-medium-contrast);
  --ion-color-medium-contrast-rgb: var(--lyceo-medium-contrast-rgb);
  --ion-color-medium-shade: var(--lyceo-medium-shade);
  --ion-color-medium-tint: var(--lyceo-medium-tint);

  /** light **/
  --ion-color-light: var(--lyceo-light);
  --ion-color-light-rgb: var(--lyceo-light-rgb);
  --ion-color-light-contrast: var(--lyceo-light-contrast);
  --ion-color-light-contrast-rgb: var(--lyceo-light-contrast-rgb);
  --ion-color-light-shade: var(--lyceo-light-shade);
  --ion-color-light-tint: var(--lyceo-light-tint);

  /** white */
  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: var(--lyceo-dark-text-color);
  --ion-color-white-contrast-rgb: var(--lyceo-dark-text-color-rgb);
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #ffffff;
}

.ion-color-white {
  /* stylelint-disable-next-line declaration-no-important */
  --ion-color-base: var(--ion-color-white) !important;
  /* stylelint-disable-next-line declaration-no-important */
  --ion-color-base-rgb: var(--ion-color-white-rgb) !important;
  /* stylelint-disable-next-line declaration-no-important */
  --ion-color-contrast: var(--ion-color-white-contrast) !important;
  /* stylelint-disable-next-line declaration-no-important */
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb) !important;
  /* stylelint-disable-next-line declaration-no-important */
  --ion-color-shade: var(--ion-color-white-shade) !important;
  /* stylelint-disable-next-line declaration-no-important */
  --ion-color-tint: var(--ion-color-white-tint) !important;
}
